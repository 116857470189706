import React from "react";

function Footer() {
  return (
    <footer className="w-full bg-footer-bg py-2 sm:py-3 border-t border-t-footer-bg-border">
      <div className="container mx-auto w-full flex flex-col">
        <div>
          <p className="text-head text-footer-text text-center px-4">
            Bu platform, Ahmet Buğra Kara tarafından Koin Bülteni ekibine
            armağan edilmiştir.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
