import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import ProtectedRoute from "../components/ProtectedRoute";
import {
  Login,
  Users,
  ScheduledContent,
  AddContent,
  Contents,
  Bot,
} from "../pages";
import { DataProvider } from "../context/dataContext";
import Register from "../pages/Register";
export const router = createBrowserRouter([
  {
    element: (
      <DataProvider>
        <Layout />
      </DataProvider>
    ),
    children: [
      {
        path: "/scheduled",
        element: <ProtectedRoute children={<ScheduledContent />} />,
      },
      {
        path: "/",
        index: true,
        element: <ProtectedRoute children={<AddContent />} />,
      },
      {
        path: "/contents",
        element: <ProtectedRoute children={<Contents />} />,
      },
      {
        path: "/users",
        element: <ProtectedRoute children={<Users />} />,
      },
      {
        path: "/register",
        element: <ProtectedRoute children={<Register />} />,
      },
      {
        path: "/bot",
        element: <ProtectedRoute children={<Bot />} />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Login />,
  },
]);
