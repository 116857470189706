/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet } from "react-router-dom";
import { useUserContext } from "../context/userContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../utils/notifications";
function Bot() {
  const { role, loading, clearState } = useUserContext();
  const [loadingIn, setLoadingIn] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [newBot, setNewBot] = React.useState(false);
  const [settingsModal, setSettingsModal] = React.useState(false);
  const [user, setUser] = React.useState({
    username: "",
    password: "",
    email: "",
  });
  const [settings, setSettings] = React.useState({
    bot_status: false,
    min_like_account_number: 0,
    max_like_account_number: 0,
    min_rt_account_number: 0,
    max_rt_account_number: 0,
    min_send_ms: 0,
    max_send_ms: 0,
    check_freq: 1500000,
  });
  const [tweetModal, setTweetModal] = React.useState("");
  const [tweetId, setTweetId] = React.useState("");
  const handleUsers = async () => {
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const configUser = {
        method: "GET",
        url: `${process.env.REACT_APP_API}/api/bot/users`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
      };
      const configSettings = {
        method: "GET",
        url: `${process.env.REACT_APP_API}/api/bot/settings`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
      };
      const [user, Isettings] = await Promise.all([
        axios(configUser),
        axios(configSettings),
      ]);
      setLoadingIn(false);
      if (
        user.data.status === "success" &&
        Isettings.data.status === "success"
      ) {
        setUsers(user.data.data);
        setSettings(Isettings.data.settings);
      } else {
        setUsers([]);
        setSettings({
          bot_status: false,
          like_account_number: 0,
          rt_account_number: 0,
          check_freq: 1500000,
        });
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const changeStatus = async (username, status, need_login, i) => {
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/bot/single_user`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          username: username,
          status,
          need_login,
        },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === "success") {
        const newArray = users.map((item, index) => {
          if (index === i) {
            return { ...item, status: status, need_login: need_login };
          } else {
            return item;
          }
        });
        setUsers(newArray);
        notify(200, res.data.msg);
      } else {
        handleUsers();
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const removeBot = async (username, i) => {
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/bot/delete`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          username: username,
        },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        const newArray = users.filter((item, index) => index !== i);
        setUsers(newArray);
        notify(200, res.data.msg);
      } else {
        handleUsers();
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const setNewUser = async (e) => {
    e.preventDefault();
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/bot/create`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          username: user.username,
          password: user.password,
          email: user.email,
        },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        handleUsers();
        notify(200, res.data.msg);
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const setNewSettings = async (e) => {
    e.preventDefault();
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/bot/settings`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: settings,
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        handleUsers();
        notify(200, res.data.msg);
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const sendLikeRetweet = async (e) => {
    e.preventDefault();
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/bot/${tweetModal}`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: { tweet_id: tweetId },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        notify(200, `${res.data.msg}`);
        setTweetId("");
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  React.useEffect(() => {
    if (!loading && role === "super_admin") {
      handleUsers();
    }
  }, [loading, role]);
  if (role === null && !loading && role !== "super_admin") {
    return <Navigate replace to="/" />;
  }
  return (
    <>
      <div className="flex flex-col container py-10 gap-5">
        <button
          className="font-semibold bg-palatte-1 w-fit px-3 py-2 text-white rounded-md"
          disabled={loadingIn || loading}
          onClick={(e) => {
            e.preventDefault();
            setNewBot(!newBot);
          }}
        >
          New Bot
        </button>
        {newBot && (
          <div className="">
            <form
              action=""
              className="flex flex-col space-y-2"
              onSubmit={(e) => {
                setNewUser(e);
              }}
            >
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                className="w-fit border"
                value={user.username}
                onChange={(e) => {
                  setUser((u) => {
                    return { ...u, username: e.target.value };
                  });
                }}
              />
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                className="w-fit border"
                value={user.email}
                onChange={(e) => {
                  setUser((u) => {
                    return { ...u, email: e.target.value };
                  });
                }}
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                className="w-fit border"
                value={user.password}
                onChange={(e) => {
                  setUser((u) => {
                    return { ...u, password: e.target.value };
                  });
                }}
              />
              <button
                type="submit"
                className={`text-white px-3 py0.5 font-bold w-fit rounded-lg ${
                  loadingIn || loading
                    ? "bg-slate-500 cursor-wait"
                    : "bg-palatte-1"
                }`}
                disabled={loadingIn || loading}
              >
                Yeni Bot Ekle
              </button>
            </form>
          </div>
        )}
        <hr />
        <button
          className="font-semibold bg-palatte-1 w-fit px-3 py-2 text-white rounded-md"
          disabled={loadingIn || loading}
          onClick={(e) => {
            e.preventDefault();
            setSettingsModal(!settingsModal);
          }}
        >
          Settings
        </button>
        {settingsModal && (
          <div className="">
            <form
              action=""
              className="flex flex-col space-y-2"
              onSubmit={(e) => {
                setNewSettings(e);
              }}
            >
              <label htmlFor="bot_status">Bot Status</label>
              <input
                type="checkbox"
                name="bot_status"
                id="bot_status"
                className="w-fit border"
                checked={settings.bot_status}
                onChange={(e) => {
                  setSettings((u) => {
                    return { ...u, bot_status: !settings.bot_status };
                  });
                }}
              />
              <label htmlFor="min_like_account_number">
                Min Like Account Number
              </label>
              <input
                type="number"
                name="min_like_account_number"
                id="min_like_account_number"
                className="w-fit border"
                value={settings.min_like_account_number}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      min_like_account_number: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="max_like_account_number">
                Max Like Account Number
              </label>
              <input
                type="number"
                name="max_like_account_number"
                id="max_like_account_number"
                className="w-fit border"
                value={settings.max_like_account_number}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      max_like_account_number: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="min_rt_account_number">
                Min RT Account Number
              </label>
              <input
                type="number"
                name="min_rt_account_number"
                id="min_rt_account_number"
                className="w-fit border"
                value={settings.min_rt_account_number}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      min_rt_account_number: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="max_rt_account_number">
                Max RT Account Number
              </label>
              <input
                type="number"
                name="max_rt_account_number"
                id="max_rt_account_number"
                className="w-fit border"
                value={settings.max_rt_account_number}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      max_rt_account_number: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="min_send_ms">Min Send Ms</label>
              <input
                type="number"
                name="min_send_ms"
                id="min_send_ms"
                className="w-fit border"
                value={settings.min_send_ms}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      min_send_ms: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="max_send_ms">Max Send Ms</label>
              <input
                type="number"
                name="max_send_ms"
                id="max_send_ms"
                className="w-fit border"
                value={settings.max_send_ms}
                onChange={(e) => {
                  setSettings((u) => {
                    return {
                      ...u,
                      max_send_ms: parseInt(e.target.value),
                    };
                  });
                }}
              />
              <label htmlFor="check_freq">
                Bot Kontrol Süresi - 1000 = 1sn
              </label>
              <input
                type="number"
                name="check_freq"
                id="check_freq"
                className="w-fit border"
                value={settings.check_freq}
                onChange={(e) => {
                  setSettings((u) => {
                    return { ...u, check_freq: parseInt(e.target.value) };
                  });
                }}
              />
              <button
                type="submit"
                className={`text-white px-3 py0.5 font-bold w-fit rounded-lg ${
                  loadingIn || loading
                    ? "bg-slate-500 cursor-wait"
                    : "bg-palatte-1"
                }`}
                disabled={loadingIn || loading}
              >
                Ayarları Kaydet
              </button>
            </form>
          </div>
        )}
        <hr />
        <div className="flex flex-row gap-5">
          <button
            className="font-semibold bg-palatte-1 w-fit px-3 py-2 text-white rounded-md"
            disabled={loadingIn || loading}
            onClick={(e) => {
              e.preventDefault();
              setTweetModal(tweetModal === "like" ? "" : "like");
            }}
          >
            Send Like
          </button>
          <button
            className="font-semibold bg-palatte-1 w-fit px-3 py-2 text-white rounded-md"
            disabled={loadingIn || loading}
            onClick={(e) => {
              e.preventDefault();
              setTweetModal(tweetModal === "retweet" ? "" : "retweet");
            }}
          >
            Send Retweet
          </button>
        </div>
        {tweetModal !== "" && (
          <div className="">
            <form
              action=""
              className="flex flex-col space-y-2"
              onSubmit={(e) => {
                sendLikeRetweet(e);
              }}
            >
              <label htmlFor="tweet_id">Tweet Id</label>
              <input
                type="text"
                name="tweet_id"
                id="tweet_id"
                className="w-3/12 border"
                value={tweetId}
                maxLength={19}
                onChange={(e) => {
                  setTweetId(e.target.value);
                }}
              />
              <button
                type="submit"
                className={`text-white px-3 py0.5 font-bold w-fit rounded-lg ${
                  loadingIn || loading
                    ? "bg-slate-500 cursor-wait"
                    : "bg-palatte-1"
                }`}
                disabled={loadingIn || loading}
              >
                {loadingIn || loading
                  ? "Lütfen Bekleyiniz!"
                  : tweetModal === "like"
                  ? "Send Like"
                  : "Send Retweet"}
              </button>
            </form>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          {Array.isArray(users) &&
            users.length > 0 &&
            users.map((e, i) => {
              return (
                <div
                  className={`flex flex-col border border-gray-200 rounded-lg py-7 px-5 justify-between gap-7 ${
                    e.need_login && "bg-red-100"
                  } ${!e.status && "bg-gray-200"}`}
                  key={i}
                >
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Username:</span>
                      <div>{e.username}</div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Status:</span>
                      <input
                        type="checkbox"
                        name="status"
                        checked={e.status}
                        onChange={() => {
                          const newArray = users.map((item, index) => {
                            if (index === i) {
                              return { ...item, status: !item.status };
                            } else {
                              return item;
                            }
                          });
                          setUsers(newArray);
                        }}
                      />
                    </div>
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Need Login:</span>
                      <input
                        type="checkbox"
                        name="need_login"
                        checked={e.need_login}
                        onChange={() => {
                          const newArray = users.map((item, index) => {
                            if (index === i) {
                              return { ...item, need_login: !item.need_login };
                            } else {
                              return item;
                            }
                          });
                          setUsers(newArray);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-3">
                    <button
                      onClick={(b) => {
                        b.preventDefault();
                        removeBot(e.username, i);
                      }}
                      disabled={loadingIn || loading}
                      className={`text-white w-full font-bold rounded-sm py-2 ${
                        loadingIn || loading ? "bg-gray-600" : "bg-danger"
                      }`}
                    >
                      Sil
                    </button>
                    <button
                      onClick={(b) => {
                        b.preventDefault();
                        changeStatus(e.username, e.status, e.need_login, i);
                      }}
                      disabled={loadingIn || loading}
                      className={`text-white w-full font-bold rounded-sm py-2 ${
                        loadingIn || loading ? "bg-gray-600" : "bg-clear"
                      }`}
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Bot;
