import {
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  HANDLE_REFRESH,
  HANDLE_LOADING,
  CLEAR_STATE,
} from "../utils/action";

const reducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    return {
      email: null,
      token: null,
      role: null,
      loading: true,
    };
  }
  if (action.type === HANDLE_LOGIN) {
    localStorage.setItem("access_token", action.payload.access_token);
    localStorage.setItem("email", action.payload.email);
    return {
      ...state,
      email: action.payload.email,
      token: action.payload.access_token,
    };
  }
  if (action.type === HANDLE_LOGOUT) {
    localStorage.removeItem("email");
    localStorage.removeItem("access_token");
    return { email: null, token: null, role: null, loading: false };
  }
  if (action.type === HANDLE_REFRESH) {
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("access_token");
    if (email === action.payload.email) {
      return {
        ...state,
        email: email,
        token: token,
        role: action.payload.role,
      };
    } else {
      return { ...state, email: null, token: null, role: null };
    }
  }
  if (action.type === HANDLE_LOADING) {
    return { ...state, loading: action.payload };
  }
  return { ...state };
};

export default reducer;
