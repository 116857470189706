import React from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useUserContext } from "../context/userContext";
import { useDataContext } from "../context/dataContext";
function Header() {
  const { handleLogout, loading, role } = useUserContext();
  const { url } = useDataContext();

  const [menu, setMenu] = React.useState(false);
  const handleLogoutAction = (e) => {
    handleLogout();
  };
  return (
    <nav className="w-full bg-gradient-to-r from-from to-to">
      <div className="container mx-auto">
        <div className="flex flex-row text-white w-full sm:justify-between gap-1 sm:gap-2 lg:gap-3">
          <button
            onClick={() => {
              setMenu(!menu);
            }}
            className="col-span-1 flex sm:hidden place-items-center justify-center bg-white bg-opacity-16 py-6 px-3"
          >
            <RxHamburgerMenu className="" />
          </button>
          <div className="flex py-2 place-items-center w-full justify-center sm:w-auto sm:justify-normal">
            <Link reloadDocument={url === "" ? false : true} to="/">
              <img
                src="/koinbulteni_logo_white.png"
                className="w-48"
                alt="KB logo"
              />
            </Link>
          </div>
          <div className="hidden sm:flex col-span-4 justify-center sm:w-full sm:space-x-5 place-items-center py-3">
            <div className="hidden sm:block justify-center">
              <ul className="flex flex-row space-x-6">
                <Link
                  reloadDocument={url === "" ? false : true}
                  to="/"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                  aria-disabled={loading}
                >
                  İçerik Ekle
                </Link>
                <Link
                  to="/contents"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                  aria-disabled={loading}
                >
                  Tüm İçerikler
                </Link>
                <Link
                  to="/scheduled"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                  aria-disabled={loading}
                >
                  Zamanlanan İçerikler
                </Link>
                {role === "super_admin" && (
                  <Link
                    to="/users"
                    className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                    aria-disabled={loading}
                  >
                    Kullanıcılar
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link
                    to="/bot"
                    className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                    aria-disabled={loading}
                  >
                    Bot
                  </Link>
                )}
              </ul>
            </div>
          </div>
          <div className="hidden sm:flex col-span-2 justify-center sm:w-fit sm:space-x-5  place-items-center py-3">
            <button
              onClick={(e) => {
                handleLogoutAction(e);
              }}
              className={`bg-danger bg-opacity-70 px-4 text-head py-1.5 rounded-md font-bold transition ease-in-out delay-150 hover:bg-opacity-100 ${
                loading ? "cursor-wait" : ""
              }`}
              disabled={loading}
            >
              Çıkış Yap
            </button>
          </div>
        </div>
        {menu ? (
          <div className="flex flex-col space-y-5 w-full sm:hidden py-3 px-2">
            <div className="flex justify-center w-full">
              <ul className="flex flex-col space-y-3 text-center text-white">
                <Link
                  reloadDocument={url === "" ? false : true}
                  to="/"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                >
                  İçerik Ekle
                </Link>
                <Link
                  to="/contents"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                >
                  Tüm İçerikler
                </Link>
                <Link
                  to="/scheduled"
                  className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                >
                  Zamanlanan İçerikler
                </Link>
                {role === "super_admin" && (
                  <Link
                    to="/users"
                    className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                  >
                    Kullanıcılar
                  </Link>
                )}
                {role === "super_admin" && (
                  <Link
                    to="/bot"
                    className="font-semibold border-b-2 border-transparent hover:border-b-2 hover:border-white transition ease-in-out delay-50"
                    aria-disabled={loading}
                  >
                    Bot
                  </Link>
                )}
                <div className="flex sm:hidden col-span-2 justify-center sm:justify-end sm:w-full sm:space-x-5 place-items-center py-3">
                  <button
                    onClick={(e) => {
                      handleLogoutAction(e);
                    }}
                    className={`bg-danger bg-opacity-70 px-4 text-head py-1.5 rounded-md font-bold transition ease-in-out delay-150 hover:bg-opacity-100 ${
                      loading ? "cursor-wait" : ""
                    }`}
                    disabled={loading}
                  >
                    Çıkış Yap
                  </button>
                </div>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
}

export default Header;
