/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../context/userContext";

function ProtectedRoute({ children }) {
  const { handleRefresh, token, loading } = useUserContext();
  const checkRef = React.useRef(true);
  React.useEffect(() => {
    if (checkRef.current === true) {
      handleRefresh();
    }
  }, [checkRef.current]);
  if (token === null && !loading) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
}

export default ProtectedRoute;
