/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { AiFillCopy } from "react-icons/ai";
import { useDataContext } from "../context/dataContext";
import { useUserContext } from "../context/userContext";
import axios from "axios";
function Contents() {
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = React.useState(Number(params.get("page")) || 0);
  const {
    handleCopy,
    handleLength,
    length,
    contents,
    handleContents,
    handleLoaderData,
  } = useDataContext();
  const { token, loading, clearState } = useUserContext();
  const handlePage = (source) => {
    if (source === "inc") {
      if (page < (length / 16).toFixed(0) - 1) {
        setPage(page + 1);
        handleContents(page + 1);
        setNewUrl(page + 1);
      }
    } else {
      if (page !== 0) {
        setPage(page - 1);
        handleContents(page - 1);
        setNewUrl(page - 1);
      }
    }
  };
  const setNewUrl = (page) => {
    const newUrl = new URL(window.location.href);
    if (page === 0 || !page) {
      newUrl.searchParams.delete("page");
    } else newUrl.searchParams.set("page", page);
    window.history.pushState({}, "", newUrl.toString());
  };
  const contentLoader = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token !== null) {
      try {
        const config = {
          method: "GET",
          url: `${process.env.REACT_APP_API}/api/firlatici/contents?id=${page}`,
          withCredentials: true,
          headers: {
            "secret-key": process.env.REACT_APP_SECRET_KEY,
            Authorization: `Bearer ${access_token}`,
          },
        };
        setNewUrl(page);
        const response = await axios(config);
        if (response.data.status === 200) {
          handleLength(response.data.data.length);
          handleLoaderData(response.data.data.data);
        }
      } catch (error) {
        if (error.response.data.statusCode === 401) {
          clearState();
          localStorage.removeItem("email");
          localStorage.removeItem("access_token");
          window.location.reload();
        }
      }
    }
  };
  React.useEffect(() => {
    if (token !== null && !loading) {
      contentLoader();
    }
  }, [token]);
  return (
    <>
      <main className="w-full">
        <div className="container mx-auto pb-10">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 py-10">
            {contents.length !== 0
              ? contents.map((item, i) => (
                  <div
                    key={i}
                    className="border border-gray-200 rounded-lg overflow-hidden"
                  >
                    <div className="p-4">
                      <div className="pb-2 text-sm font-bold flex flex-row w-full">
                        <span className="bg-palatte-7 text-white py-0.5 px-1 rounded-md cursor-default">
                          Paylaşıldı
                        </span>
                        <Link
                          to="/"
                          onClick={() => {
                            handleCopy(item);
                          }}
                          title="Kopyala"
                          className="text-slate-900 font-bold text-2xl justify-end w-full flex"
                        >
                          <AiFillCopy />
                        </Link>
                      </div>
                      <div className="text-gray-700 pb-4 text-center text-md font-bold cursor-default">
                        İçerik
                      </div>
                      <div className="text-sm pb-4">{item.content}</div>
                      <hr />
                      <div className="grid grid-cols-1 mt-4 gap-y-2 text-sm">
                        {item.tweet_id !== null ? (
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Tweet Bağlantısı:
                            </span>{" "}
                            {
                              <a
                                className="underline font-medium text-blue-700"
                                target="_blank"
                                rel="noreferrer"
                                href={`https://twitter.com/KoinBulteni/status/${item.tweet_id}`}
                              >
                                Tweet
                              </a>
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {item.message_id !== null ? (
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Telegram Bağlantısı:
                            </span>{" "}
                            {
                              <a
                                className="underline font-medium text-blue-700"
                                target="_blank"
                                rel="noreferrer"
                                href={`https://t.me/KoinBulteniHaber/${item.message_id}`}
                              >
                                Mesaj
                              </a>
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <span className="text-slate-900 font-bold cursor-default">
                            Telegram?:
                          </span>{" "}
                          {item.is_telegram ? "Evet" : "Hayır"}
                        </div>
                        <div>
                          <span className="text-slate-900 font-bold cursor-default">
                            Twitter?:
                          </span>{" "}
                          {item.is_twitter ? "Evet" : "Hayır"}
                        </div>
                        <div>
                          <span className="text-slate-900 font-bold cursor-default">
                            Oluşturan:
                          </span>{" "}
                          {item.created_by}
                        </div>
                        {item.scheduled_date !== null ? (
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Zamanlama Tarihi:
                            </span>{" "}
                            {new Date(item.scheduled_date).toLocaleString(
                              "tr-TR",
                              { timeZone: "Europe/Istanbul" }
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <span className="text-slate-900 font-bold cursor-default">
                            Oluşturulma Tarihi:
                          </span>{" "}
                          {new Date(item.createdAt).toLocaleString("tr-TR", {
                            timeZone: "Europe/Istanbul",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
          <div className="flex flex-row justify-center w-full gap-4 mt-3">
            <button
              onClick={() => handlePage("dec")}
              className={`flex justify-center items-center px-2 py-3 bg-slate-900 text-white rounded-lg ${
                page === 0 ? "bg-slate-600 cursor-not-allowed" : ""
              }`}
            >
              Önceki Sayfa
            </button>
            <button
              onClick={() => handlePage("inc")}
              className={`flex justify-center items-center px-2 py-3 bg-slate-900 text-white rounded-lg ${
                page < (length / 16).toFixed(0) - 1
                  ? ""
                  : "bg-slate-600 cursor-not-allowed"
              }`}
            >
              Sonraki Sayfa
            </button>
          </div>
        </div>
      </main>
      <Outlet />
    </>
  );
}

export default Contents;
