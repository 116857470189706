/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet } from "react-router-dom";
import { FaTwitter, FaTelegram } from "react-icons/fa";
import { MdSchedule } from "react-icons/md";
import { useDataContext } from "../context/dataContext";
import Modal from "../components/Modal";
import { ToastContainer } from "react-toastify";
function AddContent() {
  const {
    handleFileUpload,
    handleModal,
    handleFirlat,
    handleInputCount,
    handleInput,
    handleButtonClick,
    url,
    text,
    textCount,
    activeTwitter,
    activeSchedule,
    activeTelegram,
    selectedDateTime,
    loading,
    isModalOpen,
    selectedFile,
  } = useDataContext();

  React.useEffect(() => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const links = text.match(linkRegex) || [];
    const linkCount = links.length * 24;
    const newText = text.replace(linkRegex, "");
    handleInputCount(newText.length + linkCount);
  }, [text]);
  return (
    <>
      <main className="w-full h-screen">
        <div className="container mx-auto">
          <div className="w-full px-6 sm:40 xl:px-64 flex flex-col py-10 space-y-5">
            <div className="font-semibold text-2xl w-full text-center">
              İçerik Ekle
            </div>
            <div className="w-full flex flex-col justify-center space-y-2">
              <textarea
                disabled={loading}
                onChange={(e) => {
                  handleInput(e);
                }}
                value={text}
                name="text"
                className={`w-full px-4 py-2 shadow-md text-gray-700 bg-gray-100 border-2 focus:outline-none rounded-md ${
                  textCount > 279
                    ? "border-danger"
                    : textCount > 240
                    ? " border-palatte-3"
                    : textCount > 2
                    ? " border-palatte-7"
                    : " border-gray-600"
                }`}
                rows="8"
                placeholder="İçerik..."
              ></textarea>
              <div className="flex justify-end place-items-center h-full text-gray-700">
                {textCount} / 280
              </div>
            </div>
            <div className="w-full grid grid-cols-6 justify-start space-x-8">
              <div className="col-span-3 flex flex-col space-y-2">
                <label
                  htmlFor="upload"
                  className="text-sm font-semibold text-gray-700 mb-1"
                >
                  Yükle
                </label>
                <input
                  disabled={loading}
                  value={selectedFile}
                  id="upload"
                  type="file"
                  accept="image/*"
                  className="px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md shadow-md focus:outline-none focus:border-indigo-500 text-xs"
                  onChange={(e) => handleFileUpload(e)}
                />
              </div>
              <div className="col-span-3 flex flex-col space-y-2">
                <div className="text-sm font-semibold text-gray-700">
                  Görsel URL
                </div>
                <div className="flex place-items-center h-full px-4 py-2 text-gray-700 bg-gray-100 shadow-md border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500">
                  {url !== "" ? (
                    <a
                      href={`https://api-kb.bugrakara.dev/public/uploads/${url}`}
                      className="font-semibold text-xsx text-blue-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`https://api-kb.bugrakara.dev/public/uploads/${url}`}
                    </a>
                  ) : (
                    <span className="font-semibold text-xsx text-danger cursor-default">
                      Yüklenmiş görsel bulunmuyor!
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-12 bg-b">
              <div className="col-span-6 flex flex-col space-y-2">
                <div className="text-sm font-semibold text-gray-700">
                  Sosyal Medya ve Zamanlayıcı
                </div>
                <div className="flex flex-row w-full place-items-center space-x-5">
                  <button
                    className={`flex items-center justify-center align-middle w-10 h-10 rounded-full transition ease-in-out delay-100 text-white shadow-md ${
                      activeTwitter ? "bg-green-500" : "bg-danger"
                    }`}
                    onClick={() => handleButtonClick("twitter")}
                    disabled={loading}
                  >
                    <FaTwitter size={20} />
                  </button>
                  <button
                    className={`flex items-center justify-center w-10 h-10 rounded-full transition ease-in-out delay-100 text-white shadow-md ${
                      activeTelegram ? "bg-green-500" : "bg-danger"
                    }`}
                    onClick={(e) => handleButtonClick("telegram")}
                    disabled={loading}
                  >
                    <FaTelegram size={20} />
                  </button>
                  <button
                    className={`flex items-center justify-center w-10 h-10 rounded-full transition ease-in-out delay-100 text-white shadow-md ${
                      activeSchedule ? "bg-green-500" : "bg-danger"
                    }`}
                    onClick={(e) => handleButtonClick("schedule")}
                    disabled={loading}
                  >
                    <MdSchedule size={20} />
                  </button>
                </div>
              </div>
              {activeSchedule ? (
                <div className="col-span-4 space-y-3">
                  <div className="text-sm font-semibold text-gray-700">
                    Zamanlayıcı
                  </div>
                  <input
                    disabled={loading}
                    name="selectedDateTime"
                    type="datetime-local"
                    value={selectedDateTime}
                    onChange={handleInput}
                    className="py-1 px-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
              ) : (
                <div className="col-span-4 space-y-3"></div>
              )}
              <div className="col-span-2">
                <div className="flex justify-end place-items-end h-full text-gray-700">
                  <button
                    disabled={loading || text === "" || textCount < 3}
                    onClick={handleModal}
                    className={`text-white px-5 text-head py-3 rounded-md font-bold transition ease-in-out delay-150 hover:bg-opacity-100  ${
                      loading ||
                      text === "" ||
                      textCount < 3 ||
                      (!activeTelegram && !activeTwitter)
                        ? "bg-gray-600 cursor-not-allowed"
                        : "bg-palatte-7 cursor-pointer"
                    }`}
                  >
                    Fırlat Gitsin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        loading={loading}
        isOpen={isModalOpen}
        onClose={handleModal}
        onApprove={handleFirlat}
        text={text}
        url={url}
        isTwitter={activeTwitter}
        isTelegram={activeTelegram}
        isSchedule={activeSchedule}
        scheduleTime={selectedDateTime}
      />
      <Outlet />
      <ToastContainer className="z-20" />
    </>
  );
}

export default AddContent;
