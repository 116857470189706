/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../context/userContext";

function Login() {
  const { handleLogin, loading, token, handleRefresh } = useUserContext();
  const [form, setForm] = useState({ email: "", password: "" });
  const handleChange = (e) => {
    setForm((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(form.email, form.password);
  };
  useEffect(() => {
    handleRefresh();
  }, []);
  if (token !== null && !loading) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <>
        <div className="h-screen flex items-center w-full">
          <div className="container justify-center w-full flex">
            <div className="py-5 px-3 m-3 rounded-lg shadow flex flex-col space-y-3 w-fit">
              <div className="text-center font-black">Login</div>
              <form
                action=""
                className="space-y-2"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <label htmlFor="email">E-posta adresiniz</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="w-full border"
                  value={form.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="password">Şifreniz</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="w-full border"
                  value={form.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <button
                  type="submit"
                  className={`text-white px-3 py0.5 font-bold rounded-lg ${
                    loading ? "bg-slate-500 cursor-wait" : "bg-palatte-1"
                  }`}
                  disabled={loading}
                >
                  Giriş Yap
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
