import {
  HANDLE_BUTTON_CLICK,
  HANDLE_CONTENTS,
  HANDLE_COPY,
  HANDLE_FILE_SELECT,
  HANDLE_FILE_UPLOAD,
  HANDLE_INPUT,
  HANDLE_INPUT_COUNT,
  HANDLE_LENGTH,
  HANDLE_LOADING,
  HANDLE_LOGOUT,
  HANDLE_MODAL,
  HANDLE_SCHEDULED_CONTENTS,
  HANDLE_SCHEDULED_CONTENTS_REMOVE,
  HANDLE_SUBMIT,
} from "../utils/action";

const dataReducer = (state, action) => {
  if (action.type === HANDLE_LOADING) {
    return { ...state, loading: !state.loading };
  }
  if (action.type === HANDLE_COPY) {
    return { ...state, text: action.payload.content };
  }
  if (action.type === HANDLE_FILE_UPLOAD) {
    return { ...state, url: action.payload };
  }
  if (action.type === HANDLE_FILE_SELECT) {
    return { ...state, selectedFile: action.payload };
  }
  if (action.type === HANDLE_LENGTH) {
    return { ...state, length: action.payload };
  }
  if (action.type === HANDLE_BUTTON_CLICK) {
    const e = action.payload;
    if (e === "twitter") {
      return { ...state, activeTwitter: !state.activeTwitter };
    } else if (e === "telegram") {
      return { ...state, activeTelegram: !state.activeTelegram };
    } else if (e === "schedule") {
      return {
        ...state,
        activeSchedule: !state.activeSchedule,
        selectedDateTime: "",
      };
    } else {
      return { ...state };
    }
  }
  if (action.type === HANDLE_INPUT) {
    const { value, name } = action.payload.target;
    if (name === "text") {
      const linkRegex = /(https?:\/\/[^\s]+)/g;
      const links = value.match(linkRegex) || [];
      return { ...state, text: value };
    } else {
      return { ...state, selectedDateTime: value };
    }
  }
  if (action.type === HANDLE_INPUT_COUNT) {
    return { ...state, textCount: action.payload };
  }
  if (action.type === HANDLE_MODAL) {
    return { ...state, isModalOpen: !state.isModalOpen };
  }
  if (action.type === HANDLE_SUBMIT) {
    return {
      ...state,
      url: "",
      text: "📍",
      textCount: 0,
      activeTwitter: true,
      activeTelegram: true,
      activeSchedule: false,
      selectedDateTime: "",
      selectedFile: "",
      loading: false,
      isModalOpen: false,
    };
  }
  if (action.type === HANDLE_SCHEDULED_CONTENTS) {
    return { ...state, scheduledContents: [...action.payload] };
  }
  if (action.type === HANDLE_SCHEDULED_CONTENTS_REMOVE) {
    const newContents = state.scheduledContents.filter((e) => {
      return e.uuid !== action.payload;
    });
    return { ...state, scheduledContents: [...newContents] };
  }
  if (action.type === HANDLE_LOGOUT) {
    localStorage.removeItem("email");
    localStorage.removeItem("access_token");
    window.location.reload();
    return { ...state };
  }
  if (action.type === HANDLE_CONTENTS) {
    return { ...state, contents: action.payload };
  }
  return { ...state };
};

export default dataReducer;
