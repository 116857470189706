/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useDataContext } from "../context/dataContext";
import { AiFillCopy } from "react-icons/ai";
import { useUserContext } from "../context/userContext";
import axios from "axios";
import { ToastContainer } from "react-toastify";
function ScheduledContent() {
  const {
    handleCopy,
    handleCancel,
    handleScheduledContents,
    scheduledContents,
  } = useDataContext();
  const { token, loading, clearState } = useUserContext();

  const scheduledContentLoader = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token !== null) {
      try {
        const config = {
          method: "GET",
          url: `${process.env.REACT_APP_API}/api/firlatici/scheduled`,
          withCredentials: true,
          headers: {
            "secret-key": process.env.REACT_APP_SECRET_KEY,
            Authorization: `Bearer ${access_token}`,
          },
        };
        const response = await axios(config);
        if (response.data.status === 200) {
          handleScheduledContents(response.data.data);
        }
      } catch (error) {
        if (error.response.data.statusCode === 401) {
          clearState();
          localStorage.removeItem("email");
          localStorage.removeItem("access_token");
          window.location.reload();
        }
      }
    }
  };
  React.useEffect(() => {
    if (token !== null && !loading) {
      scheduledContentLoader();
    }
  }, [token]);
  if (scheduledContents.length === 0) {
    return (
      <main className="w-full">
        <div className="container mx-auto">
          <div className="py-5 font-medium">
            Zamanlanmış bir içerik şu anda bulunmamaktadır.
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <>
        <main className="w-full">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 py-10">
              {scheduledContents !== undefined
                ? scheduledContents?.map((item, i) => (
                    <div
                      key={i}
                      className="border border-gray-200 rounded-lg overflow-hidden"
                    >
                      <div className="p-4">
                        <div className="pb-2 text-sm font-bold flex flex-row w-full">
                          <span className="bg-palatte-3 text-white py-0.5 px-1 rounded-md cursor-default">
                            Zamanlandı
                          </span>
                          <Link
                            to="/"
                            onClick={() => {
                              handleCopy(item);
                            }}
                            title="Kopyala"
                            className="text-slate-900 font-bold text-2xl justify-end w-full flex"
                          >
                            <AiFillCopy />
                          </Link>
                        </div>
                        <div className="text-gray-700 pb-4 text-center text-md font-bold cursor-default">
                          İçerik
                        </div>
                        <div className="text-sm pb-4">{item.content}</div>
                        <hr />
                        <div className="grid grid-cols-1 my-4 gap-y-2 text-sm">
                          {item.tweet_id !== null ? (
                            <div>
                              <span className="text-slate-900 font-bold cursor-default">
                                Tweet Bağlantısı:
                              </span>{" "}
                              {
                                <a
                                  className="underline font-medium text-blue-700"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://twitter.com/bugra1g/status/${item.tweet_id}`}
                                >
                                  Tweet
                                </a>
                              }
                            </div>
                          ) : (
                            ""
                          )}
                          {item.message_id !== null ? (
                            <div>
                              <span className="text-slate-900 font-bold cursor-default">
                                Telegram Bağlantısı:
                              </span>{" "}
                              {
                                <a
                                  className="underline font-medium text-blue-700"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://t.me/c/1947421637/${item.message_id}`}
                                >
                                  Mesaj
                                </a>
                              }
                            </div>
                          ) : (
                            ""
                          )}
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Telegram?:
                            </span>{" "}
                            {item.is_telegram ? "Evet" : "Hayır"}
                          </div>
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Twitter?:
                            </span>{" "}
                            {item.is_twitter ? "Evet" : "Hayır"}
                          </div>
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Oluşturan:
                            </span>{" "}
                            {item.created_by}
                          </div>
                          {item.scheduled_date !== null ? (
                            <div>
                              <span className="text-slate-900 font-bold cursor-default">
                                Zamanlama Tarihi:
                              </span>{" "}
                              {new Date(item.scheduled_date).toLocaleString(
                                "tr-TR",
                                { timeZone: "Europe/Istanbul" }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div>
                            <span className="text-slate-900 font-bold cursor-default">
                              Oluşturulma Tarihi:
                            </span>{" "}
                            {new Date(item.createdAt).toLocaleString("tr-TR", {
                              timeZone: "Europe/Istanbul",
                            })}
                          </div>
                        </div>
                        <hr />
                        <button
                          onClick={() => {
                            handleCancel(item.uuid);
                          }}
                          className="bg-danger hover:bg-opacity-80 transition delay-100 ease-out px-2 py-1 rounded-md font-bold text-white mt-4 w-full"
                        >
                          İptal Et
                        </button>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </main>
        <Outlet />
        <ToastContainer className="z-20" />
      </>
    );
  }
}

export default ScheduledContent;
