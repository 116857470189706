import React from "react";
import { Outlet } from "react-router-dom";
import { useUserContext } from "../context/userContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../utils/notifications";
function Register() {
  const { role, loading } = useUserContext();
  const [loadingIn, setLoadingIn] = React.useState(false);
  const [user, setUser] = React.useState({
    email: "",
    role: "editor",
    password: "",
    full_name: "",
  });
  const handleChange = (e) => {
    setUser((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/auth/register`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: user,
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 201) {
        notify(200, res.data.message);
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
      }
      notify(400, error?.response?.data?.message ?? "Login failed!");
      setLoadingIn(false);
    }
  };
  if (role === null && !loading && role !== "super_admin") {
    return <Navigate replace to="/" />;
  }
  return (
    <>
      <div className="flex container py-10 justify-center">
        <div className="py-5 px-3 m-3 rounded-lg shadow flex flex-col space-y-3 h-fit w-1/2">
          <div className="text-center font-black">Üyelik Oluştur</div>
          <form
            action=""
            className="flex flex-col space-y-3 w-full"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <label htmlFor="email">E-posta adresi</label>
            <input
              type="email"
              name="email"
              id="email"
              className="w-full border"
              value={user.email}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="password">Şifre - Güçlü</label>
            <input
              type="password"
              name="password"
              id="password"
              className="w-full border"
              value={user.password}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="password">İsim Soyisim</label>
            <input
              type="text"
              name="full_name"
              id="full_name"
              className="w-full border"
              value={user.full_name}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="password">Rol</label>
            <select
              className="w-full border"
              value={user.role}
              onChange={(e) => {
                handleChange(e);
              }}
              name="role"
            >
              <option value="editor">Editor</option>
              <option value="Super Admin">Super Admin</option>
            </select>
            <button
              type="submit"
              className={`text-white px-3 py-2 font-bold rounded-lg ${
                loadingIn || loading
                  ? "bg-slate-500 cursor-wait"
                  : "bg-palatte-1"
              }`}
              disabled={loadingIn || loading}
            >
              Üyelik Oluştur
            </button>
          </form>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Register;
