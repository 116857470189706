import React from "react";
import { FaSpinner } from "react-icons/fa";

const Modal = ({
  isOpen,
  onClose,
  onApprove,
  text,
  url,
  isTwitter,
  isTelegram,
  isSchedule,
  scheduleTime,
  loading,
}) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-50 w-3/4">
        <h2 className="text-lg font-bold pb-2">İçeriği Gözden Geçir</h2>
        <hr />
        <p className="py-2">Göndermeyi onaylıyor musunuz?</p>
        <div className="py-5 px-2 mt-2 mb-4 border-2 flex flex-col space-y-3">
          <div>
            <span className="font-bold">İçerik: </span>
            <span>{text}</span>
          </div>
          <div>
            <span className="font-bold">Görsel URL: </span>
            <span>
              {url !== "" ? (
                <a
                  href={`http://localhost:3001/public/uploads/${url}`}
                  className="font-semibold text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Görsel için tıkla
                </a>
              ) : (
                "Görsel bulunamadı!"
              )}
            </span>
          </div>
          <div>
            <span className="font-bold">Twitter: </span>
            <span>{isTwitter ? "Evet" : "Hayır"}</span>
          </div>
          <div>
            <span className="font-bold">Telegram: </span>
            <span>{isTelegram ? "Evet" : "Hayır"}</span>
          </div>
          {isSchedule ? (
            <div>
              <span className="font-bold">Zamanlanma Tarihi: </span>
              <span>{scheduleTime}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-end">
          <button
            className={`px-4 py-2 bg-danger font-semibold text-white rounded-lg mr-2 ${
              loading ? "cursor-wait" : ""
            }`}
            onClick={onClose}
            disabled={loading}
          >
            İptal
          </button>
          <button
            className={`px-4 py-2 bg-palatte-7 font-semibold text-white rounded-lg ${
              loading ? "cursor-wait" : ""
            }`}
            onClick={onApprove}
            disabled={loading}
          >
            {loading ? <FaSpinner className="animate-spin mr-1" /> : "Gönder"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
