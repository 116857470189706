export const HANDLE_LOGIN = "HANDLE_CHANGE";
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const HANDLE_SUBMIT = "HANDLE_SUBMIT";
export const HANDLE_REFRESH = "HANDLE_REFRESH";
export const HANDLE_LOADING = "HANDLE_LOADING";
export const HANDLE_FILE_UPLOAD = "HANDLE_FILE_UPLOAD";
export const HANDLE_BUTTON_CLICK = "HANDLE_BUTTON_CLICK";
export const HANDLE_INPUT = "HANDLE_INPUT";
export const HANDLE_INPUT_COUNT = "HANDLE_INPUT_COUNT";
export const HANDLE_MODAL = "HANDLE_MODAL";
export const HANDLE_COPY = "HANDLE_COPY";
export const HANDLE_FILE_SELECT = "HANDLE_FILE_SELECT";
export const HANDLE_SCHEDULED_CONTENTS = "HANDLE_SCHEDULED_CONTENTS";
export const HANDLE_SCHEDULED_CONTENTS_REMOVE =
  "HANDLE_SCHEDULED_CONTENTS_REMOVE";
export const HANDLE_LENGTH = "HANDLE_LENGTH";
export const HANDLE_CONTENTS = "HANDLE_CONTENTS";
export const CLEAR_STATE = "CLEAR_STATE";
