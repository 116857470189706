import axios from "axios";
import React, { useContext, useReducer } from "react";
import reducer from "../reducer/reducer";
import {
  HANDLE_LOADING,
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  HANDLE_REFRESH,
  CLEAR_STATE,
} from "../utils/action";
import { notify } from "../utils/notifications";

const initialState = {
  email: null,
  token: null,
  role: null,
  loading: true,
};

const UserContext = React.createContext();
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const clearState = () => {
    dispatch({ type: CLEAR_STATE });
  };
  const handleLogin = async (email, password) => {
    dispatch({ type: HANDLE_LOADING, payload: true });
    try {
      const config = {
        url: `${process.env.REACT_APP_API}/api/auth/login`,
        method: "POST",
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
        },
        data: {
          email: email,
          password: password,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        dispatch({ type: HANDLE_LOGIN, payload: response.data.data });
        notify(200, response.data.message);
      }
      dispatch({ type: HANDLE_LOADING, payload: false });
    } catch (error) {
      notify(400, error?.response?.data?.message ?? "Login failed!");
      dispatch({ type: HANDLE_LOADING, payload: false });
    }
  };
  const handleLogout = async () => {
    dispatch({ type: HANDLE_LOADING, payload: true });
    try {
      const access_token = localStorage.getItem("access_token");
      const config = {
        url: `${process.env.REACT_APP_API}/api/auth/logout`,
        method: "POST",
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await axios(config);
      dispatch({ type: HANDLE_LOADING, payload: false });
      if (response.status === 200) {
        notify(200, response.data.message);
        dispatch({ type: HANDLE_LOGOUT });
        window.location.reload();
      }
    } catch (error) {
      notify(400, error?.response?.data?.message ?? "Logout failed!");
      dispatch({ type: HANDLE_LOADING, payload: false });
      if (error.response.data.statusCode === 401) {
        dispatch({ type: HANDLE_LOGOUT });
      }
    }
  };
  const handleRefresh = async () => {
    const access_token = localStorage.getItem("access_token");
    if (access_token !== null && access_token !== undefined) {
      dispatch({ type: HANDLE_LOADING, payload: true });
      try {
        const config = {
          url: `${process.env.REACT_APP_API}/api/auth/user-info`,
          method: "GET",
          withCredentials: true,
          headers: {
            "secret-key": process.env.REACT_APP_SECRET_KEY,
            Authorization: `Bearer ${access_token}`,
          },
        };
        const response = await axios(config);
        dispatch({ type: HANDLE_LOADING, payload: false });
        if (response.status === 200) {
          dispatch({ type: HANDLE_REFRESH, payload: response.data.data });
        } else {
          dispatch({ type: HANDLE_LOGOUT });
          window.location.reload();
        }
      } catch (error) {
        dispatch({ type: HANDLE_LOADING, payload: false });
        if (error.response.data.statusCode === 401) {
          dispatch({ type: HANDLE_LOGOUT });
        }
      }
    } else {
      dispatch({ type: HANDLE_LOADING, payload: false });
    }
  };
  return (
    <UserContext.Provider
      value={{ ...state, handleLogout, handleLogin, handleRefresh, clearState }}
    >
      {children}
    </UserContext.Provider>
  );
};

// make sure use
export const useUserContext = () => {
  return useContext(UserContext);
};
