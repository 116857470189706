/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Outlet, Link } from "react-router-dom";
import { useUserContext } from "../context/userContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { notify } from "../utils/notifications";
function Users() {
  const { role, loading, clearState } = useUserContext();
  const [loadingIn, setLoadingIn] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  const handleUsers = async () => {
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API}/api/auth/get-users`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        setUsers(res.data.data);
      } else {
        setUsers([]);
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      setLoadingIn(false);
    }
  };
  const changeStatus = async (email, status, i) => {
    setLoadingIn(true);
    const access_token = localStorage.getItem("access_token");
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API}/api/auth/set-status`,
        withCredentials: true,
        headers: {
          "secret-key": process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          email: email,
          status: status,
        },
      };
      const res = await axios(config);
      setLoadingIn(false);
      if (res.data.status === 200) {
        const newArray = users.map((item, index) => {
          if (index === i) {
            return { ...item, status: status };
          } else {
            return item;
          }
        });
        setUsers(newArray);
        notify(200, res.data.message);
      } else {
        handleUsers();
      }
    } catch (error) {
      if (error.response.data.statusCode === 401) {
        localStorage.removeItem("email");
        localStorage.removeItem("access_token");
        window.location.reload();
        clearState();
      }
      notify(400, error?.response?.data?.message ?? "Failed to change status");
      setLoadingIn(false);
    }
  };
  React.useEffect(() => {
    if (!loading && role === "super_admin") {
      handleUsers();
    }
  }, [loading, role]);
  if (role === null && !loading && role !== "super_admin") {
    return <Navigate replace to="/" />;
  }
  return (
    <>
      <div className="flex flex-col container py-10 gap-5">
        <Link
          to="/register"
          className="font-semibold bg-palatte-1 w-fit px-3 py-2 text-white rounded-md"
          aria-disabled={loading}
        >
          New User
        </Link>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          {Array.isArray(users) &&
            users.length > 0 &&
            users.map((e, i) => {
              return (
                <div
                  className="flex flex-col border border-gray-200 rounded-lg py-7 px-5 justify-between gap-7"
                  key={e.uid}
                >
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Email:</span>
                      <div>{e.email}</div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Rol:</span>
                      <div>{e.role}</div>
                    </div>
                    <div className="flex flex-row justify-between">
                      <span className="font-semibold">Status:</span>
                      <input
                        type="checkbox"
                        name="status"
                        checked={e.status}
                        onChange={() => {
                          const newArray = users.map((item, index) => {
                            if (index === i) {
                              return { ...item, status: !item.status };
                            } else {
                              return item;
                            }
                          });
                          setUsers(newArray);
                        }}
                      />
                    </div>
                  </div>
                  <button
                    onClick={(b) => {
                      b.preventDefault();
                      changeStatus(e.email, e.status, i);
                    }}
                    disabled={loadingIn || loading}
                    className={`text-white font-bold rounded-sm py-2 ${
                      loadingIn || loading ? "bg-gray-600" : "bg-clear"
                    }`}
                  >
                    Kaydet
                  </button>
                </div>
              );
            })}
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Users;
